.sbPDF {
    height: 21600px !important;
}

.sbPDFPage {
    /* width: 595px;
    height: 842px; */
    width: 100%;
    background: #ffffff;
}

.sbPDFHeader {
    margin-top: 132px;
}

.sbPDFTitle {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    text-align: center;
    color: #BF802F;
}

.sbrBody {
    margin-left: 68px;
    margin-right: 64px;
}

.sbrSubheading {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    color: #BF802F;
}

.recommendationsParagraph {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    color: #000000;
    margin-top: 30px;
}

.recommendationsParagraph1 {
    margin-bottom: 20px;
}

.sbrBlueSubSubHeading {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #3BB9C4;
}

.sbrSubSubHeadingItem {
    display: flex;
}

.financialHealthCols {
    display: flex;
    /* margin: 10px; */
}

.investmentSummaryRow {
    margin: 8px;
}

.nonFlex {
    display: inline;
}

.sbrChildren {
    display: inline;
}

.sbrGoals {
    display: flex;
    margin-bottom: 20px;
}

.financialHealthCol1 {
    width: 150px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8)
}

.goalRecommendationOuterContainer {
    height: 100%;
}

.sbrAdvisorSignature {
    display: inline-block;
}

.sbreportSignature {
    transform: scale(1);
}

.docXButton {
    margin-top: 30px;
    margin-left: 1110px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1;
}