.dashboardContainer {
  background: #f5f8fa;
  box-sizing: border-box;
  width: 1280px;
  height: 800px;
}

.logoContainer {
  position: absolute;
  top: -12px !important;
  left: 31px;
  transform: scale(0.6);
}

.users,
.sessions {
  background: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100px;
  height: 30px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users {
  position: absolute;
  top: -34px;
  left: 970px;
}

.sessions {
  position: absolute;
  top: -34px;
  left: 1080px;
}

.borderSelected {
  top: -38px;
  padding-bottom: 4px;
  border-top: 4px solid #0c3957;
  border-left: 4px solid #0c3957;
  border-right: 4px solid #0c3957;
}

.searchBar {
  position: absolute;
  left: 2px;
  right: 0%;
  top: 100px;
  bottom: 0%;

  /* white */

  background: #ffffff;
  /* box/standard */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  /* border-radius: 8px; */
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 1275px;
  height: 80px;
}

.searchContainer {
  margin-top: 17px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
}

.searchFields {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 66px;
}

.searchName {
  text-align: start;
  width: 116px;
}

.searchField {
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
  height: 26px;
  width: 300px;
  padding: 10px 23px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #7a7a7a;
  padding-left: 40px;
  margin-left: 20px;
}

.searchLabel {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
  margin-top: 8px;
}

.orderBy {
  margin-top: 7px;
  margin-left: 20px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
  border: 1px solid #0c3957;
  padding: 4px;
}

.resultsContainer {
  position: absolute;
  top: 180px !important;
  width: 1280px;
  height: 570px;
  background: #f5f8fa;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.resultsContainer::-webkit-scrollbar {
  display: none;
}

.resultsHeading {
  margin-top: -20px;
}

.userSessionsContainer {
}

.selectUsers {
  position: absolute;
  top: 0px;
  left: 757px;
  height: 48px;
  width: 227px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  text-align: center;
  color: #bf802f;
}

.selectSessions {
  left: 1019px;
}

.unSelectedTab {
  opacity: 0.5;
}

.createUserButton {
  position: absolute;
  top: 4px;
  right: -275px;
}

.dashboardAccessDeniedText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  color: #232323;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
}

.dashboard404 {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  color: #232323;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 22%;
}

.dashboardAccessDenied {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteBox {
  position: absolute;
  top: 32%;
  left: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 500px;
  height: 260px;
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 0px 5px 16px rgb(0 0 0 / 55%);
  z-index: 5;
}

.dashboardDialogButtons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.blockInputPanel:not(.deleteBox) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.dashboardLoaderAnimation {
  position: absolute;
  top: 49%;
  left: 600px;
  z-index: 6;
}

.usersDropdownDashboard {
  position: relative;
  top: 6px;
  right: -35px;
}

.usersFoundLabel {
  margin-top: -47px;
  margin-left: 323px;
  width: 130px;
}

.sessionsDropdownDashboard {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 40px;
  align-items: center;
}

.pagination {
  top: 750px;
  display: flex;
  position: absolute;
  justify-content: center;
  width: 1275px;
  z-index: 3;
}

.dashboardResultsLabel {
  position: absolute;
  top: 755px;
  left: 1040px;
  z-index: 4;
}

.dashboardNoRecords {
  color: #b40125;
  z-index: 3;
  align-self: center;
}
